


/* regular variant  */
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/Satoshi-Regular.woff2') format('otf');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/public/fonts/Satoshi-Italic.woff2') format('otf');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/public/fonts/Satoshi-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/public/fonts/Satoshi-Bold.woff2') format('otf');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/public/fonts/Satoshi-Black.woff2') format('otf');
}

body {
  margin: 0;
  background-color: #292929;
  box-sizing: border-box;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color:#fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
